import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, Modal, notification, Select, Space } from 'antd';
import { downloadCSVTransactions } from 'store/transactions';
import { useToggle } from 'hooks/useToggle';
import { UserSelect } from 'components/Form/UserSelect';

const disabledDate = (current) => current && current > new Date();

const sortOptions = [{ value: 'toAccountName', label: 'Room' }];

const DownloadTransactionsModal = () => {
  const dispatch = useDispatch();
  const { activate, deactivate, active } = useToggle();
  const [filter, setFilter] = useState({ sort: { createdAt: 'asc' } });

  const updateFilter = useCallback((payload) => {
    setFilter((current) => ({ ...current, ...payload }));
  }, []);

  const onDateFilter = useCallback(
    (current) => {
      updateFilter({
        range:
          current?.map((moment, idx) => {
            let date;
            if (idx === 0) {
              date = moment.utc().startOf('day');
            } else {
              date = moment.utc().endOf('day');
            }
            return date.unix() * 1000;
          }) || [],
      });
    },
    [updateFilter],
  );

  const onSortChange = useCallback(
    (current) => {
      updateFilter({ sort: { [current]: 'asc', createdAt: 'asc' } });
    },
    [updateFilter],
  );

  const onUserChange = useCallback(
    (current) => {
      updateFilter({ fromUser: current });
    },
    [updateFilter],
  );

  const downloadTransactions = useCallback(async () => {
    try {
      const { data } = await dispatch(downloadCSVTransactions(filter));
      window.open(data.fileURL, '_blank');
      deactivate();
      notification.success({ message: 'File was successfully downloaded' });
    } catch (e) {
      notification.error({ message: 'Error while downloading file' });
    }
  }, [dispatch, filter, deactivate]);

  return (
    <>
      <Button type="primary" onClick={activate}>
        Download CSV
      </Button>
      <Modal
        title="Download transactions CSV"
        open={active}
        width={600}
        okText="Download"
        onOk={downloadTransactions}
        onCancel={deactivate}
      >
        <Space>
          <UserSelect style={{ width: 200 }} allowClear onChange={onUserChange} />
          <DatePicker.RangePicker onChange={onDateFilter} disabledDate={disabledDate} />
          <Select
            placeholder="Sort by"
            style={{ width: 100 }}
            onChange={onSortChange}
            allowClear
            options={sortOptions}
          />
        </Space>
      </Modal>
    </>
  );
};

export default DownloadTransactionsModal;
