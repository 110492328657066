import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Divider, notification, Space, Tooltip } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { getArchiveUrl, findAll, getInfo, getSummary, selectCraft, refreshArchive } from 'store/craft';
import { UserSelect } from 'components/Form/UserSelect';
import CraftTable from 'components/Craft/CraftTable';
import CraftSummary from 'components/Craft/CraftSummary';
import CraftSearch from 'components/Craft/CraftSearch';
import { RedoOutlined } from '@ant-design/icons';

export const Craft = () => {
  const dispatch = useDispatch();
  const { list, summary, info, loading } = useSelector(selectCraft);
  const { params, handleTableChange, addFilter } = useTableParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(findAll(params));
    dispatch(getSummary(params));
    dispatch(getInfo({ userId: params.userId }));
  }, [dispatch, params]);

  const refresh = useCallback(async () => {
    try {
      await dispatch(refreshArchive());
      notification.success({
        message: 'Archive refreshed successfully triggered. Wait for couple of minutes to see the changes.',
      });
    } catch (e) {
      notification.error({
        message: 'Error while refreshing archive',
      });
    }
  }, [dispatch]);

  const download = useCallback(async () => {
    try {
      const { data } = await dispatch(getArchiveUrl());
      if (data.fileURL) {
        window.open(data.fileURL, '_blank');
        notification.success({
          message: 'File was successfully downloaded',
        });
      } else {
        notification.error({
          message: 'Error while downloading file',
        });
      }
    } catch (e) {
      notification.error({
        message: 'Error while downloading file',
      });
    }
  }, [dispatch, params]);

  const isSummaryEmpty = useMemo(() => Object.keys(summary).length === 0 && summary.constructor === Object, [summary]);

  const columnsBefore = useMemo(
    () => [
      {
        title: t('craft.table.user'),
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        render: (user) => user?.username || '-',
      },
    ],
    [t],
  );

  return (
    <>
      <Space>
        <CraftSearch
          onChange={addFilter}
          addonBefore={
            <UserSelect
              placeholder={t('craft.search.user')}
              filter={{ role: 'user' }}
              allowClear
              onChange={addFilter('userId')}
              style={{ width: 200 }}
            />
          }
        />
        {info?.lastUpload && (
          <span>
            {t('craft.lastupdate.label')}:{' '}
            {new Date(info.lastUpload).toLocaleString('ru-RU', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        )}
      </Space>
      <Tooltip title={'Refresh Archive'} placement={'bottom'}>
        <Button style={{ float: 'right', marginRight: '2em' }} type="primary" onClick={refresh}>
          <RedoOutlined />
        </Button>
      </Tooltip>
      <Button style={{ float: 'right', marginRight: '1em' }} type="primary" onClick={download}>
        Download
      </Button>

      <Divider />
      {!isSummaryEmpty && (
        <>
          <CraftSummary summary={summary} />
          <Divider />
        </>
      )}
      <CraftTable list={list} loading={loading} columnsBefore={columnsBefore} onChange={handleTableChange} />
    </>
  );
};
