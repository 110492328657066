import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Checkbox, Col, message, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getMyAccounts, selectList } from 'store/accounts';
import { getPreferences, selectPreferences, setTournamentRooms } from 'store/preferences';
import { ROOMS, ROOMS_MAP } from 'config/tournaments';

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { list: accounts } = useSelector(selectList);
  const preferences = useSelector(selectPreferences);
  const [value, setValue] = useState([]);

  useEffect(() => {
    dispatch(getMyAccounts());
    dispatch(getPreferences());
  }, [dispatch]);

  const handleSave = useCallback(async () => {
    await dispatch(setTournamentRooms(value));
    message.success('Настройки обновлены');
  }, [dispatch, value]);

  useEffect(() => {
    if (preferences?.tournamentRooms?.length > 0) {
      setValue(preferences.tournamentRooms);
    } else {
      setValue(accounts.map((room) => ROOMS_MAP[room] || room));
    }
  }, [accounts, preferences]);

  return (
    <Row gutter={10}>
      <Col span={8}>
        <Card title={t('my_rooms')} actions={[<Button key="save" icon={<SaveOutlined />} onClick={handleSave} />]}>
          <Checkbox.Group value={value} onChange={setValue}>
            <Row gutter={10}>
              {ROOMS.map((room) => (
                <Col span={8} key={room}>
                  <Checkbox key={room} value={room}>
                    {room}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Card>
      </Col>
    </Row>
  );
};

export default Settings;
