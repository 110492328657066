import {
  UserOutlined,
  TrophyOutlined,
  BarChartOutlined,
  ReadOutlined,
  DollarOutlined,
  RiseOutlined,
  CalculatorOutlined,
  UserSwitchOutlined,
  TeamOutlined,
  MoneyCollectOutlined,
  WalletOutlined,
  PauseCircleOutlined,
  CoffeeOutlined,
  DeliveredProcedureOutlined,
  FundOutlined,
  RocketOutlined,
  UnlockOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { permissions } from 'config/permissions';
import Users from 'pages/Admin/Users';
import { Tournaments } from 'pages/Admin/Tournaments';
import { UserStatistics } from 'pages/Admin/UserStatistics';
import { AccountStatistics } from 'pages/Admin/AccountStatistics';
import { AccountLogs } from 'pages/Admin/AccountLogs';
import { Analytics } from 'pages/Admin/Analytics';
import { PersonalTransactions } from 'pages/Admin/PersonalTransactions';
import { InternalTransactions } from 'pages/Admin/InternalTransactions';
import { ExternalTransactions } from 'pages/Admin/ExternalTransactions';
import { Split } from 'pages/Admin/Split';
import { Freeze } from 'pages/Admin/Freeze';
import { PlayingMyself } from 'pages/Admin/PlayingMyself';
import { SerialEvaluation } from 'pages/Admin/SerialEvaluation';
import { Totals } from 'pages/Admin/Totals';
import { getSplitPackagesCount } from 'store/packages';
import { getAllActiveRequestsCount } from 'store/playing-myself';
import { Craft } from 'pages/Admin/Craft';
import Permissions from 'pages/Admin/Permissions';
import Notifications from 'pages/Admin/Notifications';

const MENU_GROUPS = {
  statistics: {
    key: 'statistics',
    label: 'Statistics',
    icon: <BarChartOutlined />,
  },
  transactions: {
    key: 'transactions',
    label: 'Transactions',
    icon: <DollarOutlined />,
  },
};

export const ROUTES = [
  {
    path: '/users',
    Component: Users,
    permission: permissions.CanReadUsers,
    menu: {
      label: 'Users',
      group: null,
      icon: <UserOutlined />,
    },
  },
  {
    path: '/permissions',
    Component: Permissions,
    permission: permissions.CanManageAdmins,
    menu: {
      label: 'Permissions',
      group: null,
      icon: <UnlockOutlined />,
    },
  },
  {
    path: '/tournaments',
    Component: Tournaments,
    permission: permissions.CanReadTournaments,
    menu: {
      label: 'Tournaments',
      group: null,
      icon: <TrophyOutlined />,
    },
  },
  {
    path: '/users-stats',
    Component: UserStatistics,
    permission: permissions.CanReadUserStats,
    menu: {
      label: 'Users',
      group: MENU_GROUPS.statistics,
      icon: <ReadOutlined />,
    },
  },
  {
    path: '/accounts-stats',
    Component: AccountStatistics,
    permission: permissions.CanReadAccountStats,
    menu: {
      label: 'Accounts',
      group: MENU_GROUPS.statistics,
      icon: <DollarOutlined />,
    },
  },
  {
    path: '/accounts-logs',
    Component: AccountLogs,
    permission: permissions.CanReadAccountLogs,
    menu: {
      label: 'Account logs',
      group: MENU_GROUPS.statistics,
      icon: <RiseOutlined />,
    },
  },
  {
    path: '/personal-transactions',
    Component: PersonalTransactions,
    permission: permissions.CanReadPersonalTransactions,
    menu: {
      label: 'Personal',
      group: MENU_GROUPS.transactions,
      icon: <UserSwitchOutlined />,
    },
  },
  {
    path: '/internal-transactions',
    Component: InternalTransactions,
    permission: permissions.CanReadInternalTransactions,
    menu: {
      label: 'Internal',
      group: MENU_GROUPS.transactions,
      icon: <TeamOutlined />,
    },
  },
  {
    path: '/external-transactions',
    Component: ExternalTransactions,
    permission: permissions.CanReadExternalTransactions,
    menu: {
      label: 'External',
      group: MENU_GROUPS.transactions,
      icon: <MoneyCollectOutlined />,
    },
  },
  {
    path: '/split',
    Component: Split,
    permission: permissions.CanReadSplit,
    menu: {
      label: 'Split',
      icon: <WalletOutlined />,
      countRequest: getSplitPackagesCount,
    },
  },
  {
    path: '/freeze',
    Component: Freeze,
    permission: permissions.CanReadFreeze,
    menu: {
      label: 'Freeze',
      icon: <PauseCircleOutlined />,
    },
  },
  {
    path: '/playing-myself',
    Component: PlayingMyself,
    permission: permissions.CanReadPlayingMyself,
    menu: {
      label: 'Playing myself',
      group: null,
      icon: <CoffeeOutlined />,
      countRequest: getAllActiveRequestsCount,
    },
  },
  {
    path: '/serial-evaluation',
    Component: SerialEvaluation,
    permission: permissions.CanReadSerialEvaluation,
    menu: {
      label: 'Serial evaluation',
      group: null,
      icon: <DeliveredProcedureOutlined />,
    },
  },
  {
    path: '/totals',
    Component: Totals,
    permission: permissions.CanReadTotals,
    menu: {
      label: 'Totals',
      group: null,
      icon: <FundOutlined />,
    },
  },
  {
    path: '/analytics',
    Component: Analytics,
    permission: permissions.CanReadAnalytics,
    menu: {
      label: 'Thing',
      group: null,
      icon: <CalculatorOutlined />,
    },
  },
  {
    path: '/craft',
    Component: Craft,
    permission: permissions.CanReadCraft,
    menu: {
      label: 'Craft',
      group: null,
      icon: <RocketOutlined />,
    },
  },
  {
    path: '/notifications',
    Component: Notifications,
    permission: permissions.CanReadCraft,
    menu: {
      label: 'Notifications',
      group: null,
      icon: <NotificationOutlined />,
    },
  },
];
